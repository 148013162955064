$box-bg-color: rgb(47, 77, 124);
$box-bg-color-dark: rgb(27, 49, 83);
$toast-color-1: #FFD679;
$toast-color-2: #EBA300;
$toast-color-3: #D48C00;
html {
  height: 100%;
  body {
    height: 100%;
    #root {
      height: 100%;
      color: white;
      h1 {
        position: absolute;
        margin-left: 50px;
      }
      .mainContainer {
        background-color: rgb(35, 57, 92);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        & > * {
          margin: 10px 0;
        }
        input[type="file"] {
          display: none;
        }
        .uploadButton {
          font-size: 1.5rem;
          background-color: $box-bg-color;
          padding: 1rem;
          cursor: pointer;
        }
        .fileName {
          font-size: 1.5rem;
          background-color: $box-bg-color;
          padding: 1rem;
          .uploadButtonSmall {
            cursor: pointer;
            margin-right: 1rem;
          }
        }
        button {
          font-size: 2rem;
          width: auto;
          padding: 0.7rem;
          outline: none;
          cursor: pointer;
          border: 0.2rem solid black;
          border-radius: 0.7em;
          box-shadow: 0.3rem 0.3rem 1.2rem rgba(0, 0, 0, 0.164);
          background-color: $toast-color-2;
          &:hover {
            background-color: $toast-color-3;
          }
          &:disabled {
            background-color: $toast-color-1;
            color: rgb(209, 195, 175);
          }
        }
        .roomIdTextArea {
          cursor: pointer;
          font-size: 2rem;
          font-family: 'Courier New', Courier, monospace;
          font-weight: bold;
          background-color: $box-bg-color-dark;
          padding: 0.5rem;
          border-radius: 0.5rem;
          box-shadow: inset 0.1rem 0.1rem 0.7rem rgba(0, 0, 0, 0.144),
                      0.3rem 0.3rem 1.2rem rgba(0, 0, 0, 0.164);
        }
        .infoBox {
          background-color: $box-bg-color;
          padding: 0.7rem;
          border-radius: 0.4rem;
          &::before {
            content: '';
            position: absolute;
            margin-left: 72.46px;
            transform: translate(-50%, -1.2rem) rotate(45deg);
            background-color: $box-bg-color;
            width: 1rem;
            height: 1rem;
          }
        }
        .downloadButton {
          font-size: 1.2rem;
          padding: 0.3rem;
          background-color: $box-bg-color;
          text-decoration: none;
          color: inherit;
          &:visited {
            color: none;
          }
        }
        .fileTransferProgress {
          height: 2rem;
          width: 15rem;
          -webkit-appearance: none;
          appearance: none;
          border: none;
          background-color: $box-bg-color-dark;
          box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.192);
          border-radius: 0.3rem;
          &::-webkit-progress-bar,
          &::-webkit-progress-value {
            border-radius: 0.3rem;
          }
          &::-webkit-progress-value {
            background-color: $toast-color-2;
          }
          &::-webkit-progress-bar {
            background-color: $box-bg-color-dark;
            box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.192);
          }
          &::-moz-progress-bar {
            border-radius: 0.3rem;
            background-color: $toast-color-2;
          }
        }
        .error {
          color: rgb(255, 86, 86);
          padding: 0.5rem;
          background-color: $box-bg-color;
        }
      }
    }
  }
}